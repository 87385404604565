import { Form } from 'atomic/legacy/obj.form'
import { ListNoBullets } from 'site/src/components/atm.list-no-bullets/ListNoBullets.component'
import { FieldsWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { PaginationContainer } from 'site/src/components/legacy/obj.pagination-container/pagination.container'
import { PaginationRow } from 'site/src/components/legacy/pagination-wrapper.component'
import { SpecialitiesField } from 'site/src/components/legacy/specialities-field.component'
import NumberOfResults from 'site/src/components/mol.number-of-results/number-of-results.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { CosmicjsEspecialidadesConnection } from 'site/src/data/graphql/graphql-types'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getPathUrl } from 'utils/path'
import { isDoctorUrl, normalizeUrl } from 'utils/url'
import { Col, Grid, Row, Separator } from 'atomic'
import { getListJsonLd } from 'site/src/components/legacy/mol.seo/build-list-json-ld'
import { FuseSearch } from 'site/src/components/mol.local-search/fuse-search.component'
import { IndexParams } from 'site/src/components/mol.local-search/fuse-search.utils'
import { MediaCell } from 'site/src/components/mol.media-cell/media-cell.component'
import { graphql, navigate } from 'gatsby'
import * as queryString from 'query-string'
import React from 'react'
import { Placeholder } from 'atomic/legacy/mol.placeholder'
import SvgBuscaSemResultado from 'atomic/atm.svg-icon/ic-busca-sem-resultado'

interface Item {
  title: string
  url: string
  especialidades: string[]

  image: string
  imageAlt: string
  publishedDate: string
  autor: string
  description: string
}

export interface ScientificArticlesTemplateQueryParams {
  especialidade: string
  [key: string]: unknown
}

export interface ScientificArticlesTemplateProps {
  list: Item[]
  type_slug: string
  allCosmicjsEspecialidades: Partial<CosmicjsEspecialidadesConnection>
}
const ScientificArticlesTemplate: React.FunctionComponent<PageProps<
  ScientificArticlesTemplateProps
>> = props => {
  const onValueChange = value => {
    const param: ScientificArticlesTemplateQueryParams = value.value && {
      especialidade: value.value
    }
    navigate(`${props.location.pathname}?${queryString.stringify(param)}`)
  }
  const isDoctor = isDoctorUrl(props.location.pathname)

  const queryParam: ScientificArticlesTemplateQueryParams = queryString.parse(
    props.location.search
  ) as any
  const especialidade = queryParam.especialidade

  const [items, setItems] = React.useState(props.pageContext.list)

  const data = items.filter(
    item =>
      !especialidade ||
      (item.especialidades && item.especialidades.includes(especialidade))
  )
  const onResult = list => {
    if (list) {
      setItems(list)
    }
  }
  
  const indexParams: IndexParams<Item> = {
    data: props.pageContext.list,
    keys: [
      {
        name: 'title',
        weight: 0.6
      },
      {
        name: 'autor',
        weight: 0.1
      },
      {
        name: 'especialidades',
        weight: 0.3
      }
    ]
  }

  return (
    <IndexLayout location={props.location}>
      <Grid>
        <TitleWithBreadcrumbRow title={'Artigos científicos'} />
        <Row mb bottom='xs'>
          <Col xs={12} md={6}>
            <FieldsWrapper>
              <FuseSearch
                id='input-scientific-articles-search'
                indexParams={indexParams}
                onResultChange={onResult}
                placeholder='Buscar por palavra-chave'
              />
            </FieldsWrapper>
            <Separator />
          </Col>
          <Col xs={12} mdOffset={2} md={4}>
            <Form id='form-scientific-articles' onSubmit={null}>
              <SpecialitiesField
                allCosmicjsEspecialidades={
                  props.pageContext.allCosmicjsEspecialidades
                }
                onValueChange={onValueChange}
                initialValueSlug={especialidade}
              />
            </Form>
            <Separator />
          </Col>
        </Row>
      </Grid>
      <Grid>
        <Row mb>
          <Col xs={12} md={12}>
            {data.length > 0 ? (
              <PaginationContainer>
                {paginationProps => {
                  const startIndex =
                    (paginationProps.pagination.current - 1) *
                    paginationProps.limit.current
                  const endIndex =
                    paginationProps.pagination.current *
                    paginationProps.limit.current

                  const list = data.slice(startIndex, endIndex)
                  const numberOfItems = items.length

                  const siteUrl = props.data.site.siteMetadata.siteUrl
                  const currentPageUrl = normalizeUrl(
                    `${siteUrl}${getPathUrl(
                      appPaths.scientificArticles.path,
                      isDoctor
                    )}`
                  )

                  return (
                    <>
                      <SEO
                        jsonld={getListJsonLd(
                          list.map(item => ({ url: `${siteUrl}${item.url}` }))
                        )}
                        socialMedia={{
                          canonicalUrl: currentPageUrl,
                          title: 'Artigos científicos',
                          image:
                            'http://www.fleury.com.br/Util/img/fleury-logo.png',
                          imageAlt: 'Logo do fleury',
                          description:
                            'Leia os principais artigos científicos do Grupo Fleury e saiba mais sobre exames entre outros fatos relevantes.'
                        }}
                      />
                      <Row mt mb>
                        <Col>
                          <NumberOfResults numberOfItems={numberOfItems} />
                          <Separator />
                        </Col>
                      </Row>
                      <Row center='xs' mb>
                        <Col xs={12}>
                          <ListNoBullets id='list-scientific-articles'>
                            {list &&
                              list.map(item => (
                                <React.Fragment key={item.title}>
                                  <MediaCell
                                    title={item.title}
                                    imgSrc={item.image}
                                    imgAlt={item.imageAlt}
                                    content={item.description}
                                    autor={item.autor}
                                    date={item.publishedDate}
                                    readMoreButton={
                                      props.data.cosmicjsInformacoesEstaticas
                                        .metadata.col2BotaoLerDetalheTexto
                                    }
                                    to={item.url}
                                    id='scientific-articles'
                                  />
                                </React.Fragment>
                              ))}
                          </ListNoBullets>
                        </Col>
                      </Row>
                      <PaginationRow
                        pagination={{
                          ...paginationProps.pagination,
                          total: data.length
                        }}
                        limit={paginationProps.limit}
                      />
                    </>
                  )
                }}
              </PaginationContainer>
            ) : (
              <Placeholder
                icon={<SvgBuscaSemResultado />}
                title={'Nenhum resultado encontrado'}
                description='Tente buscar outro termo ou ligue para nossa central de atendimento.'
              />
            )}
          </Col>
        </Row>
      </Grid>
    </IndexLayout>
  )
}

export default ScientificArticlesTemplate

export const query = graphql`
  query ScientificArticlesTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "artigos-cientificos" }) {
      metadata {
        col2BotaoLerDetalheTexto
      }
    }
  }
`
